import { CommonModule } from '@angular/common';
import { DialogModule } from '../../dialog/dialog.module';
import { FlexModule } from '@angular/flex-layout';
import { LicenseModalComponent } from './license-modal.component';
import { LoadingModule } from '../../loading/loading.module';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [LicenseModalComponent],
  imports: [CommonModule, DialogModule, LoadingModule, FlexModule, TranslateModule],
  exports: [LicenseModalComponent],
})
export class LicenseModalModule {}
