import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoModalComponent } from './info-modal.component';
import { DialogModule } from '../../dialog/dialog.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CustomDirectiveModule } from '../../../../directives/custom-layout-directive/custom-flex-directive.module';
import { LoadingModule } from '../../loading/loading.module';
import { TranslateModule } from '@ngx-translate/core';
import { InterpolateDirective } from '../../../../../core/directives/interpolation/interpolate.directive';

@NgModule({
  declarations: [InfoModalComponent],
  imports: [
    CommonModule,
    DialogModule,
    FlexLayoutModule,
    CustomDirectiveModule,
    LoadingModule,
    TranslateModule,
    InterpolateDirective,
  ],
  exports: [InfoModalComponent],
})
export class InfoModalModule {}
