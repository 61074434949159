import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Lesson } from '../../dataset/Lesson';
import { UserContextService } from '../../context/user-context/user-context.service';
import { LessonsApiService } from '../../api/lessons-api/lessons-api.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LessonsContextService } from '../../context/lessons-context/lessons-context.service';

@Injectable()
export class LessonResolver {
  constructor(
    private lessonsApiService: LessonsApiService,
    private lessonsContext: LessonsContextService,
    private userContext: UserContextService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Lesson> {
    const user = this.userContext.get();

    if (user) {
      return this.lessonsApiService.getLesson(route.params['lesson_id']).pipe(
        tap((lesson: Lesson) => {
          console.log('LessonResolver', lesson)

          this.lessonsContext.nextCurrentLesson(lesson);
        }),
      );
    } else {
      return this.lessonsApiService
        .getLesson(route.params['lesson_id'])
        .pipe(tap((lesson: Lesson) => this.lessonsContext.nextCurrentLesson(lesson)));
    }
  }
}
