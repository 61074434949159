import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SubscribeType } from '../subscribe-form/subscribe-form.component';
import { User } from '../../../../dataset/User';
import { Price } from '../../../../dataset/Price';
import { Nullish } from '../../../helpers/ts.helpers';

@Component({
  selector: 'app-subscribe-form-header',
  templateUrl: './subscribe-form-header.component.html',
  styleUrls: ['./subscribe-form-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscribeFormHeaderComponent {
  @Input() type: SubscribeType;
  @Input() price: Price;
  @Input() user: Nullish<User>;
}
