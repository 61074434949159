<h2 class="st-h2 st-center header-title">
  <ng-container *ngIf="user && user.trial && user.trial === 'inactive'; else subscriptionHeader">
    {{ '7-day free trial' | translate }}
  </ng-container>

  <ng-template #subscriptionHeader>
    <ng-container *ngIf="type === 'month'">
      {{ 'Monthly Subscription' | translate }}
    </ng-container>

    <ng-container *ngIf="type === 'year' || type === 'super'">
      {{ 'Yearly Subscription' | translate }}
    </ng-container>

    <ng-container *ngIf="type === 'ignore'">
      {{ price.name ?? '' | translate }}
    </ng-container>
  </ng-template>
</h2>
