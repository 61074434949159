import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FirstTimeClassWarningModalComponent } from './first-time-class-warning-modal.component';
import { DialogModule } from '../../dialog/dialog.module';
import { ShowHideCheckboxModule } from '../../show-hide-checkbox/show-hide-checkbox.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CustomDirectiveModule } from '../../../../directives/custom-layout-directive/custom-flex-directive.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [FirstTimeClassWarningModalComponent],
  imports: [
    CommonModule,
    ShowHideCheckboxModule,
    DialogModule,
    FlexLayoutModule,
    CustomDirectiveModule,
    TranslateModule,
  ],
  exports: [FirstTimeClassWarningModalComponent],
})
export class FirstTimeClassWarningModalModule {}
