import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserContextService } from './user-context/user-context.service';
import { StorageContextService } from './storage-context/storage-context.service';
import { ProgressCasesContextService } from './progress-cases-context/progress-cases-context.service';
import { PackagesContextService } from './packages-context/packages-context.service';
import { PricesContextService } from './prices-context/prices-context.service';
import { AchievementsContextService } from './achievements-context/achievements-context.service';
import { EventsContextService } from './events-context/events-context.service';
import { ChallengesContextService } from './challenges-context/challenges-context.service';
import { MediaContextService } from './media-context/media-context.service';
import { CardsContextService } from './cards-context/cards-context.service';
import { LessonsContextService } from './lessons-context/lessons-context.service';
import { QuizImagesContextService } from './quiz-images-context/quiz-images-context.service';
import { CertificatesContextService } from './certificates-context/certificates-context.service';
import { ContextService } from './context.service';
import { ExtraPricesContextService } from './extra-prices-context/extra-prices-context.service';
import { UpsellPricesContextService } from './upsell-prices-context/upsell-prices-context.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    StorageContextService,
    UserContextService,
    ProgressCasesContextService,
    PackagesContextService,
    PricesContextService,
    AchievementsContextService,
    EventsContextService,
    ChallengesContextService,
    MediaContextService,
    CardsContextService,
    LessonsContextService,
    QuizImagesContextService,
    CertificatesContextService,
    ContextService,
    ExtraPricesContextService,
    UpsellPricesContextService,
  ],
})
export class ContextModule {}
