<!--filter selected lesson-->
<div class="date-time layout-row layout-align-center layout-justify-center padding-15">
  <div class="flex auto date-selection">
    <app-date-input
      class="underline"
      [(date)]="date"
      [up]="true"
      [min]="today" />
  </div>

  <div class="flex auto layout-row padding time-selection">
    <my-drop-down
      class="flex"
      *ngIf="hour"
      [(value)]="hour"
      [maxHeight]="180"
      [options]="hours"
      [placeholder]="'Hour' | translate" />

    <my-drop-down
      class="flex"
      *ngIf="minute"
      [(value)]="minute"
      [maxHeight]="180"
      [options]="minutes"
      [placeholder]="'Minutes' | translate" />

    <my-drop-down
      class="flex"
      *ngIf="am"
      [(value)]="am"
      [options]="ampms"
      placeholder="am/pm" />
  </div>
</div>
