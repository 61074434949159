import { NgModule } from '@angular/core';
import { AuthComponent } from './auth.component';
import { AuthRoutingModule } from './auth-routing.module';
import { CommonModule } from '@angular/common';
import { DashboardHeaderModule } from '../layout/dashboard-header/dashboard-header.module';
import { DashboardFooterModule } from '../layout/dashboard-footer/dashboard-footer.module';

@NgModule({
  imports: [AuthRoutingModule, CommonModule, DashboardHeaderModule, DashboardFooterModule],
  declarations: [AuthComponent],
})
export class AuthModule {}
