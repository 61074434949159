import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelpCaloriesComponent } from './help-calories.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { StFormFieldModule } from '../../../../../../shared/components/ui/st-form-field/st-form-field.module';
import { GenderDropdownModule } from '../../../../../../shared/components/ui/gender-dropdown/gender-dropdown.module';
import { DateInputModule } from '../../../../../../shared/components/ui/date-input/date-input.module';
import { ReactiveFormsModule } from '@angular/forms';
import { StSwitcherModule } from '../../../../../../shared/components/ui/st-switcher/st-switcher.module';
import { CustomDirectiveModule } from '../../../../../../shared/directives/custom-layout-directive/custom-flex-directive.module';
import { CustomInputService } from '../../../../../../services/custom-input/custom-input.service';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [HelpCaloriesComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    StFormFieldModule,
    GenderDropdownModule,
    DateInputModule,
    ReactiveFormsModule,
    StSwitcherModule,
    CustomDirectiveModule,
    TranslateModule,
  ],
  providers: [CustomInputService],
  exports: [HelpCaloriesComponent],
})
export class HelpCaloriesModule {}
