import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { SendMessageService } from '../../services/send-message/send-message.service';
import { AppConfiguration } from '../../services/configuration/app.configuration';
import { StorageContextService } from '../../../context/storage-context/storage-context.service';

@Component({
  selector: 'app-cookie-notice',
  templateUrl: './cookie-notice.component.html',
  styleUrls: ['./cookie-notice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookieNoticeComponent {
  @Output() disableCookieNotice = new EventEmitter<void>();

  constructor(
    public appConfiguration: AppConfiguration,
    private storageContext: StorageContextService,
    private sendMessageService: SendMessageService
  ) {}

  disableCookieNoticeHandler(website: string): void {
    this.disableCookieNotice.emit();
    this.storageContext.setItem('cookie-notice', 'disabled');
    this.sendMessageService.sendMessage('disable-cookie-notice', website);
  }
}
