import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpgradeToPremiumModalComponent } from './upgrade-to-premium-modal.component';
import { DialogModule } from '../../dialog/dialog.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CustomDirectiveModule } from '../../../../directives/custom-layout-directive/custom-flex-directive.module';
import { LoadingModule } from '../../loading/loading.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [UpgradeToPremiumModalComponent],
  imports: [
    CommonModule,
    DialogModule,
    FlexLayoutModule,
    CustomDirectiveModule,
    LoadingModule,
    TranslateModule,
  ],
  exports: [UpgradeToPremiumModalComponent],
})
export class UpgradeToPremiumModalModule {}
