import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PricesPaywallUpsellComponent } from './prices-paywall-upsell.component';
import { PricesModule } from '../../../../auth/sign-up/prices/prices.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CustomDirectiveModule } from '../../../../shared/directives/custom-layout-directive/custom-flex-directive.module';
import { PromoCodeModule } from '../../../../core/components/promo-code/promo-code.module';
import { NgPipesModule } from 'ngx-pipes';
import { SafeCheckoutModule } from '../safe-checkout/safe-checkout.module';
import { PriceOptionModule } from '../../../../shared/components/ui/prices/price-option/price-option.module';
import { UpsellPriceOptionModule } from '../upsell-price-option/upsell-price-option.module';

@NgModule({
  declarations: [PricesPaywallUpsellComponent],
  exports: [PricesPaywallUpsellComponent],
  imports: [
    CommonModule,
    PricesModule,
    FlexLayoutModule,
    CustomDirectiveModule,
    PromoCodeModule,
    NgPipesModule,
    SafeCheckoutModule,
    PriceOptionModule,
    UpsellPriceOptionModule,
  ],
})
export class PricesPaywallUpsellModule {}
