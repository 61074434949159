import { UrlMatchResult, UrlSegment } from '@angular/router';

export function SaleMatcher(url: UrlSegment[]): UrlMatchResult | null {
  if (url.length === 1 && url[0]?.path.includes('sale?')) {
    window.location.href = `${window.location.origin}/${url[0]?.path}`;
    return null;
  }

  return null;
}
