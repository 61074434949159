import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JoinChallengeModalComponent } from './join-challenge-modal.component';
import { DialogModule } from '../../dialog/dialog.module';
import { LoadingModule } from '../../loading/loading.module';
import { JoinHeaderModule } from './join-header/join-header.module';
import { ChallengeJoinerJoinModule } from '../../../challenge-joiner-join/challenge-joiner-join.module';
import { ChallengeJoinerDoneModule } from '../../../challenge-joiner-done/challenge-joiner-done.module';
import { PackageThumbnailModule } from '../../../package-thumbnail/package-thumbnail.module';
import { ChallengeFormModule } from '../../../challenge-form/challenge-form.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CustomDirectiveModule } from '../../../../directives/custom-layout-directive/custom-flex-directive.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [JoinChallengeModalComponent],
  imports: [
    CommonModule,
    DialogModule,
    LoadingModule,
    JoinHeaderModule,
    ChallengeJoinerJoinModule,
    ChallengeJoinerDoneModule,
    PackageThumbnailModule,
    ChallengeFormModule,
    FlexLayoutModule,
    CustomDirectiveModule,
    TranslateModule,
  ],
  exports: [JoinChallengeModalComponent],
})
export class JoinChallengeModalModule {}
