import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaypalWorkshopComponent } from './paypal-workshop.component';
import { NgxPayPalModule } from 'ngx-paypal';

@NgModule({
  declarations: [PaypalWorkshopComponent],
  imports: [CommonModule, NgxPayPalModule],
  exports: [PaypalWorkshopComponent],
})
export class PaypalWorkshopModule {}
