import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LessonFeedbackComponent } from './lesson-feedback.component';
import { IconModule } from '../../../../../../shared/components/ui/icon/icon.module';
import { FormsModule } from '@angular/forms';
import { TrackByModule } from '../../../../../../core/directives/track-by/track-by.module';
import { TranslateModule } from '@ngx-translate/core';
import { InterpolateDirective } from '../../../../../../core/directives/interpolation/interpolate.directive';

@NgModule({
  declarations: [LessonFeedbackComponent],
  imports: [
    CommonModule,
    IconModule,
    FormsModule,
    TrackByModule,
    TranslateModule,
    InterpolateDirective,
  ],
  exports: [LessonFeedbackComponent],
})
export class LessonFeedbackModule {}
