import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GetProgramModalComponent } from './get-program-modal.component';
import { DialogModule } from '../../dialog/dialog.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PaymentSystemsModule } from '../../../payment-systems/payment-systems.module';
import { PaymentMethodSelectorModule } from '../../../payment-method-selector/payment-method-selector.module';
import { LoadingModule } from '../../loading/loading.module';
import { PromoCodeModule } from '../../../../../core/components/promo-code/promo-code.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { ShowHideCheckboxModule } from '../../show-hide-checkbox/show-hide-checkbox.module';
import { InterpolateDirective } from '../../../../../core/directives/interpolation/interpolate.directive';

@NgModule({
  declarations: [GetProgramModalComponent],
  imports: [
    CommonModule,
    DialogModule,
    FlexLayoutModule,
    PaymentSystemsModule,
    PaymentMethodSelectorModule,
    LoadingModule,
    PromoCodeModule,
    TranslateModule,
    RouterLink,
    ShowHideCheckboxModule,
    InterpolateDirective,
  ],
  exports: [GetProgramModalComponent],
})
export class GetProgramModalModule {}
