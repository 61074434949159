import { inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocaleService } from '../services/locale/locale.service';

@Injectable()
export class LocaleInterceptor implements HttpInterceptor {
  readonly localeService = inject(LocaleService);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.getHostFromUrl(req.url).includes('stretchit')) return next.handle(req);

    const locale = this.localeService.currentLocale;
    const localizedReq = req.clone({
      headers: req.headers.set('x-locale', locale),
    });

    return next.handle(localizedReq);
  }

  getHostFromUrl(url: string) {
    const regex = /^(?:https?:\/\/)?(?:www\.)?([^\/:]+)/;
    const match = url.match(regex);
    return match?.[1] ?? '';
  }
}
