import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CongratsModalComponent } from './congrats-modal.component';
import { DialogModule } from '../../dialog/dialog.module';
import { FlexModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [CongratsModalComponent],
  imports: [CommonModule, DialogModule, FlexModule, TranslateModule],
  exports: [CongratsModalComponent],
})
export class CongratsModalModule {}
