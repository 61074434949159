<div class="cookie-notice">
  <div class="container cookie-notice-container">
    <div class="cookie-notice-text">
      {{
        'We use cookies to improve your online experience and the service we offer. To accept cookies please continue browsing as normal.'
          | translate
      }}
      <a href="{{ appConfiguration.website }}/cookie-policy">
        {{ 'Read our cookies policy' | translate }}</a
      >
      {{ 'for more information.' | translate }}
    </div>

    <a
      class="cookie-notice-button"
      href="#"
      (click)="$event.preventDefault(); disableCookieNoticeHandler(appConfiguration.website)">
      <svg
        class="st-stroke-black"
        width="16"
        height="16">
        <use xlink:href="#close"></use>
      </svg>
    </a>
  </div>
</div>
