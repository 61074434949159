import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LessonsApiService } from '../../api/lessons-api/lessons-api.service';
import { Lesson } from '../../dataset/Lesson';
import { filter, tap } from 'rxjs/operators';
import { LessonsContextService } from '../../context/lessons-context/lessons-context.service';
import { StorageContextService } from '../../context/storage-context/storage-context.service';

@Injectable()
export class FavoriteClassesResolver {
  constructor(
    private lessonsContext: LessonsContextService,
    private storageContext: StorageContextService,
    private lessonsApiService: LessonsApiService
  ) {}

  resolve(): Observable<Lesson[]> | void {
    const accessToken = this.storageContext.getItem('access_token');
    if (!accessToken) {
      return;
    }

    return this.lessonsApiService.getFavoriteLessons().pipe(
      filter(lessons => !!lessons),
      tap(lessons => this.lessonsContext.nextFavoriteLessons(lessons))
    );
  }
}
