import { Inject, Injectable } from '@angular/core';
import { Coupon } from '../../dataset/Coupon';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IApi } from '../../core/interrfaces/IApi';
import { Api } from '../extends/api';
import { COMMON_BASE_URL } from '../../core/tokens/common-base-url.token';

@Injectable()
export class CouponApiService extends Api implements IApi {
  constructor(
    private http: HttpClient,
    @Inject(COMMON_BASE_URL) private baseUrl: string
  ) {
    super();
  }

  validate(coupon: string): Observable<Coupon> {
    return this.http.post<Coupon>(this.buildBaseUrl('api/coupon/validate'), {
      coupon,
    });
  }

  buildBaseUrl(input: string): string {
    return this.baseUrl + '/' + input;
  }
}
