import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscribeModalComponent } from './subscribe-modal.component';
import { LoadingModule } from '../../loading/loading.module';
import { SubscribeFormModule } from '../../../subscribe/subscribe-form/subscribe-form.module';
import { DialogModule } from '../../dialog/dialog.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CustomDirectiveModule } from '../../../../directives/custom-layout-directive/custom-flex-directive.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [SubscribeModalComponent],
  imports: [
    CommonModule,
    LoadingModule,
    SubscribeFormModule,
    DialogModule,
    FlexLayoutModule,
    CustomDirectiveModule,
    TranslateModule,
  ],
  exports: [SubscribeModalComponent],
})
export class SubscribeModalModule {}
