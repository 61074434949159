import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaticPageModalComponent } from './static-page-modal.component';
import { DialogModule } from '../../dialog/dialog.module';

@NgModule({
  declarations: [StaticPageModalComponent],
  imports: [CommonModule, DialogModule],
  exports: [StaticPageModalComponent],
})
export class StaticPageModalModule {}
