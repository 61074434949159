import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Value } from '../../../../../../dataset/Value';
import find from 'lodash/find';
import first from 'lodash/first';
import range from 'lodash/range';
import dayjs from 'dayjs';

@Component({
  selector: 'app-add-date-filter',
  templateUrl: './add-date-filter.component.html',
  styleUrls: ['./add-date-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddDateFilterComponent implements OnInit {
  @Input() selectedDate: Date;

  date: Date = new Date();
  today = dayjs().startOf('day').toDate();

  hour?: Value<number>;
  hours: Value<number>[] = range(1, 13).map(num => ({ value: num, label: num + '' }));

  minute?: Value<number>;
  minutes: Value<number>[] = range(0, 60, 15).map(num => ({
    value: num,
    label: ((num + '').length === 2 ? '' : '0') + num + '',
  }));

  am?: Value<boolean>;
  ampms = [
    {
      value: true,
      label: 'am',
    },
    {
      value: false,
      label: 'pm',
    },
  ];

  ngOnInit(): void {
    this.generateTime(this.selectedDate);
  }

  private generateTime(date?: Date): void {
    this.date = date || this.date;
    const mom = dayjs(this.date);
    let am = true;
    let hour = mom.hour();
    const minute = mom.minute();
    this.minute = first(this.minutes.filter(num => minute < num.value));
    if (!this.minute) {
      this.minute = first(this.minutes);
      hour += 1;
    }
    if (hour === 24 || hour === 0) {
      hour = 12;
    } else if (hour > 12) {
      am = false;
      hour -= 12;
    } else if (hour === 12) {
      am = false;
    }
    this.hour = find(this.hours, { value: hour });
    this.am = find(this.ampms, { value: am });
  }
}
