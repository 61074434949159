import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CorrectEmailModalComponent } from './correct-email-modal.component';
import { DialogModule } from '../../dialog/dialog.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [CorrectEmailModalComponent],
  imports: [CommonModule, DialogModule, TranslateModule],
  exports: [CorrectEmailModalComponent],
})
export class CorrectEmailModalModule {}
