import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpgradeWarningModalComponent } from './upgrade-warning-modal.component';
import { DialogModule } from '../../dialog/dialog.module';
import { TranslateModule } from '@ngx-translate/core';
import { InterpolateDirective } from '../../../../../core/directives/interpolation/interpolate.directive';

@NgModule({
  declarations: [UpgradeWarningModalComponent],
  imports: [CommonModule, DialogModule, TranslateModule, InterpolateDirective],
  exports: [UpgradeWarningModalComponent],
})
export class UpgradeWarningModalModule {}
