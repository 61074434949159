import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LessonsApiService } from '../../api/lessons-api/lessons-api.service';
import { Lesson } from '../../dataset/Lesson';
import { filter, tap } from 'rxjs/operators';
import { LessonsContextService } from '../../context/lessons-context/lessons-context.service';

@Injectable()
export class DailyLessonResolver {
  constructor(
    private lessonsContext: LessonsContextService,
    private lessonsApiService: LessonsApiService
  ) {}

  resolve(): Observable<Lesson> | void {
    if (this.lessonsContext.getDailyLesson()) {
      return;
    }
    return this.lessonsApiService.getDailyLesson().pipe(
      filter(lesson => !!lesson),
      tap(lesson => this.lessonsContext.nextDailyLesson(lesson))
    );
  }
}
