import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from '../../dialog/dialog.module';
import { UpSellModalComponent } from './up-sell-modal.component';
import { PricesPaywallModule } from '../../../../../pages/personalized-program/components/prices-paywall/prices-paywall.module';
import { PricesPaywallExtraModule } from '../../../../../pages/personalized-program/components/prices-paywall-extra/prices-paywall-extra.module';
import { PricesVerticalExtraModule } from '../../../../../pages/personalized-program/components/prices-vertical-extra/prices-vertical-extra.module';
import { PricesPaywallUpsellModule } from '../../../../../pages/personalized-program/components/prices-paywall-upsell/prices-paywall-upsell.module';
import { LoadingModule } from '../../loading/loading.module';

@NgModule({
  declarations: [UpSellModalComponent],
  imports: [
    CommonModule,
    DialogModule,
    PricesPaywallModule,
    PricesPaywallExtraModule,
    PricesVerticalExtraModule,
    PricesPaywallUpsellModule,
    LoadingModule,
  ],
  exports: [UpSellModalComponent],
})
export class UpSellModalModule {}
