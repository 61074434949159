import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddAchievementModalComponent } from './add-achievement-modal.component';
import { DateInputModule } from '../../date-input/date-input.module';
import { LoadingModule } from '../../loading/loading.module';
import { DropdownModule } from '../../dropdown/dropdown.module';
import { DialogModule } from '../../dialog/dialog.module';
import { IconModule } from '../../icon/icon.module';
import { LocationInputModule } from '../../location-input/location-input.module';
import { FlexModule } from '@angular/flex-layout';
import { StFormFieldModule } from '../../st-form-field/st-form-field.module';
import { StDropdownModule } from '../../../../../core/components/ui/st-dropdown/st-dropdown.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [AddAchievementModalComponent],
  imports: [
    CommonModule,
    DialogModule,
    LoadingModule,
    DropdownModule,
    IconModule,
    DateInputModule,
    LocationInputModule,
    FlexModule,
    StFormFieldModule,
    StDropdownModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
  ],
  exports: [AddAchievementModalComponent],
})
export class AddAchievementModalModule {}
