import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddSelectedLessonComponent } from './add-selected-lesson.component';
import { LessonItemModule } from '../../../../lesson-item/lesson-item.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [AddSelectedLessonComponent],
  imports: [CommonModule, LessonItemModule, TranslateModule],
  exports: [AddSelectedLessonComponent],
})
export class AddSelectedLessonModule {}
