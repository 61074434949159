import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpsellPriceOptionComponent } from './upsell-price-option.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { InfoBadgeModule } from '../../../../shared/components/ui/info-badge/info-badge.module';
import { CustomDirectiveModule } from '../../../../shared/directives/custom-layout-directive/custom-flex-directive.module';

@NgModule({
  declarations: [UpsellPriceOptionComponent],
  imports: [
    CommonModule,
    InfoBadgeModule,
    FlexLayoutModule,
    CustomDirectiveModule,
    TranslateModule,
  ],
  exports: [UpsellPriceOptionComponent],
})
export class UpsellPriceOptionModule {}
