import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LessonRecommendationComponent } from './lesson-recommendation.component';
import { LessonItemModule } from '../../../../../../shared/components/lesson-item/lesson-item.module';
import { DateInputModule } from '../../../../../../shared/components/ui/date-input/date-input.module';
import { DropdownModule } from '../../../../../../shared/components/ui/dropdown/dropdown.module';
import { DialogModule } from '../../../../../../shared/components/ui/dialog/dialog.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CustomDirectiveModule } from '../../../../../../shared/directives/custom-layout-directive/custom-flex-directive.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ShowHideCheckboxModule } from '../../../../../../shared/components/ui/show-hide-checkbox/show-hide-checkbox.module';
import { RecommendedPackageModule } from '../../components/recommended-package/recommended-package.module';
import { StFormFieldModule } from '../../../../../../shared/components/ui/st-form-field/st-form-field.module';
import { StDropdownModule } from '../../../../../../core/components/ui/st-dropdown/st-dropdown.module';
import { LoadingModule } from '../../../../../../shared/components/ui/loading/loading.module';
import { TrackByModule } from '../../../../../../core/directives/track-by/track-by.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [LessonRecommendationComponent],
  imports: [
    CommonModule,
    LessonItemModule,
    DateInputModule,
    DropdownModule,
    DialogModule,
    FlexLayoutModule,
    CustomDirectiveModule,
    ReactiveFormsModule,
    ShowHideCheckboxModule,
    RecommendedPackageModule,
    StFormFieldModule,
    StDropdownModule,
    LoadingModule,
    TrackByModule,
    TranslateModule,
  ],
  exports: [LessonRecommendationComponent],
})
export class LessonRecommendationModule {}
