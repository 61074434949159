import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GiftComponent } from './gift.component';
import { UserResolver } from '../../resolvers/user-resolver/user-resolve.service';
import { CertPricesResolveService } from '../../resolvers/cert-prices-resolver/cert-prices-resolve.service';

const routes: Routes = [
  {
    path: 'gift',
    component: GiftComponent,
    children: [
      {
        path: 'certificate',
        loadChildren: () =>
          import('./modules/certificate/certificate.module').then(m => m.CertificateModule),
      },
      {
        path: 'certificate/activate',
        loadChildren: () =>
          import('./modules/activate-certificate/activate-certificate.module').then(
            m => m.ActivateCertificateModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [UserResolver, CertPricesResolveService],
})
export class GiftCertificateRoutingModule {}
