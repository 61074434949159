import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscribeFormHeaderComponent } from './subscribe-form-header.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [SubscribeFormHeaderComponent],
  imports: [CommonModule, TranslateModule],
  exports: [SubscribeFormHeaderComponent],
})
export class SubscribeFormHeaderModule {}
