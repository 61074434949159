import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { DashboardFooterModule } from '../layout/dashboard-footer/dashboard-footer.module';
import { DashboardHeaderModule } from '../layout/dashboard-header/dashboard-header.module';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { LicenseModalModule } from '../shared/components/ui/modals/license-modal/license-modal.module';
import { NgModule } from '@angular/core';
import { UpdateToPremiumBannerModule } from '../layout/update-to-premium-banner/update-to-premium-banner.module';

@NgModule({
  imports: [
    DashboardRoutingModule,
    DashboardHeaderModule,
    DashboardFooterModule,
    CommonModule,
    UpdateToPremiumBannerModule,
    LicenseModalModule,
  ],
  declarations: [DashboardComponent],
})
export class DashboardModule {}
