import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChallengeJoinerDoneComponent } from './challenge-joiner-done.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ChallengeJoinerDoneComponent],
  imports: [CommonModule, TranslateModule],
  exports: [ChallengeJoinerDoneComponent],
})
export class ChallengeJoinerDoneModule {}
