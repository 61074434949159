<div class="lessons-filter">
  <div
    class="layout-row"
    fxLayout="row"
    fxLayoutAlign="space-between center">
    <segment-control
      class="duration-filter"
      label="Duration"
      [segments]="activeDurationFilters"
      (selectedSegmentChange)="setDurationFilter($event)"
      [selectedSegment]="durationFilter" />

    <segment-control
      class="level-filter"
      label="Level"
      [useImage]="true"
      [segments]="dictionary.levelFilters"
      (selectedSegmentChange)="setLevelFilter($event)"
      [selectedSegment]="levelFilter" />
  </div>
</div>

<div class="lessons">
  <lessons-grid
    [columnCount]="3"
    [user]="user"
    [isBought]="selectedPackage.is_bought"
    [lessons]="filteredLessons"
    (lessonClick)="selectLessonHandler($event)"
    (subscribe)="subscribe()"
    [hideCalendarButton]="true" />
</div>
