import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelpMeModalComponent } from './help-me-modal.component';
import { DialogModule } from '../../../../shared/components/ui/dialog/dialog.module';
import { LoadingModule } from '../../../../shared/components/ui/loading/loading.module';
import { HelpQuestionsModule } from './components/help-questions/help-questions.module';
import { HelpAccountModule } from './components/help-account/help-account.module';
import { HelpPlanModule } from './components/help-plan/help-plan.module';
import { HelpCaloriesModule } from './components/help-calories/help-calories.module';
import { HelpWelcomeModule } from './components/help-welcome/help-welcome.module';
import { HelpProgramDurationComponent } from './components/help-program-duration/help-program-duration.component';
import { HelpProgramIntensityComponent } from './components/help-program-intensity/help-program-intensity.component';
import { HelpMultiselectQuestionsComponent } from './components/help-multiselect-questions/help-multiselect-questions.component';
import { HelpChallengeJoinerComponent } from './components/help-challenge-joiner/help-challenge-joiner.component';
import { HelpFinalLoaderComponent } from './components/help-final-loader/help-final-loader.component';
import { StToggleComponent } from '../../../../core/components/ui/st-toggle/st-toggle.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [HelpMeModalComponent],
  imports: [
    CommonModule,
    DialogModule,
    LoadingModule,
    HelpQuestionsModule,
    HelpAccountModule,
    HelpPlanModule,
    HelpCaloriesModule,
    HelpWelcomeModule,
    HelpProgramDurationComponent,
    HelpProgramIntensityComponent,
    HelpMultiselectQuestionsComponent,
    HelpChallengeJoinerComponent,
    HelpFinalLoaderComponent,
    StToggleComponent,
    TranslateModule,
  ],
  exports: [HelpMeModalComponent],
})
export class HelpMeModalModule {}
