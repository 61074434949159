import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelpQuestionsComponent } from './help-questions.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CustomDirectiveModule } from '../../../../../../shared/directives/custom-layout-directive/custom-flex-directive.module';
import { TrackByModule } from '../../../../../../core/directives/track-by/track-by.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [HelpQuestionsComponent],
  imports: [CommonModule, FlexLayoutModule, CustomDirectiveModule, TrackByModule, TranslateModule],
  exports: [HelpQuestionsComponent],
})
export class HelpQuestionsModule {}
