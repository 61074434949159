import { ChangeDetectionStrategy, Component } from '@angular/core';
import { GiftCertificatesService } from './services/gift-certificates.service';

@Component({
  selector: 'app-gift',
  templateUrl: './gift.component.html',
  styleUrls: ['./gift.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GiftComponent {
  constructor(public giftCertificatesService: GiftCertificatesService) {}
}
