import { UrlMatchResult, UrlSegment } from '@angular/router';

export function InfluencersMatcher(url: UrlSegment[]): UrlMatchResult | null {
  const paths = ['influencers', 'instagram'];
  const first = url[0];
  if (first && paths.includes(first.path)) {
    return { consumed: url };
  }

  return null;
}
