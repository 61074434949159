import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from '../../dialog/dialog.module';
import { ExtraSaleModalComponent } from './extra-sale-modal.component';
import { PricesPaywallModule } from '../../../../../pages/personalized-program/components/prices-paywall/prices-paywall.module';
import { PricesPaywallExtraModule } from '../../../../../pages/personalized-program/components/prices-paywall-extra/prices-paywall-extra.module';
import { PricesVerticalExtraModule } from '../../../../../pages/personalized-program/components/prices-vertical-extra/prices-vertical-extra.module';
import { AgreementService } from '../../../../../pages/personalized-program/services/agreement.service';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ExtraSaleModalComponent],
  imports: [
    CommonModule,
    DialogModule,
    PricesPaywallModule,
    PricesPaywallExtraModule,
    PricesVerticalExtraModule,
    TranslateModule,
  ],
  providers: [AgreementService],
  exports: [ExtraSaleModalComponent],
})
export class ExtraSaleModalModule {}
