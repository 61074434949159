import { ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { StorageContextService } from '../../../../context/storage-context/storage-context.service';
import { UserContextService } from '../../../../context/user-context/user-context.service';
import { tap } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class OptionalAuthGuard {
  constructor(
    private storageContext: StorageContextService,
    private userContext: UserContextService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const apiKey = route.queryParamMap.get('ApiKey') || route.queryParamMap.get('apiKey');
    if (apiKey) {
      this.storageContext.setItem('access_token', apiKey.replace(/\s/g, '+'));
      await lastValueFrom(
        this.userContext.retrieve().pipe(tap(user => this.userContext.next(user)))
      );
    }
    return true;
  }
}
