import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Value } from '../../../../../../dataset/Value';
import { Lesson } from '../../../../../../dataset/Lesson';
import { Package } from '../../../../../../dataset/Package';
import { User } from '../../../../../../dataset/User';
import { Segment } from '../../../segment-control/segment-control.component';
import { DictionaryService } from '../../../../../../services/dictionary/dictionary.service';

@Component({
  selector: 'app-add-lesson-filter',
  templateUrl: './add-lesson-filter.component.html',
  styleUrls: ['./add-lesson-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddLessonFilterComponent {
  constructor(public dictionary: DictionaryService) {}

  activeDurationFilters: Value<number>[] = [];
  levelFilter = this.dictionary.allLessonFilter as Segment;
  durationFilter = this.dictionary.allLessonFilter as Segment;

  filteredLessons: Lesson[];

  @Input() user: User | null;

  private _package: Package;

  @Input() set selectedPackage(pack: Package | undefined) {
    if (pack) {
      this._package = pack;
      const head = this.dictionary.durationFilters[0];

      if (head && 'disabled' in head) {
        head.disabled = pack.statistic_lessons['15'] === 0;
        this.activeDurationFilters = this.dictionary.durationFilters.filter(
          value => !value.disabled
        );
      }
    }
  }

  get selectedPackage(): Package {
    return this._package;
  }

  private _lessons: Lesson[];
  @Input() set lessons(value: Lesson[] | undefined) {
    if (value) {
      this._lessons = value;
      this.refresh();
    }
  }

  get lessons(): Lesson[] {
    return this._lessons;
  }

  @Output() selectLesson = new EventEmitter<Lesson>();
  @Output() subscribePackage = new EventEmitter<void>();

  @Input() packages: Package[];

  filter(lessons: Lesson[]): Lesson[] {
    return lessons.filter(lesson => {
      if (
        this.durationFilter.value &&
        (lesson.duration > (this.durationFilter.value as number) ||
          lesson.duration <= (this.durationFilter.value as number) - 15)
      ) {
        return false;
      }
      if (this.levelFilter.value === lesson.complexity || lesson.is_all_levels) {
        return true;
      }
      return !(this.levelFilter.value && lesson.complexity !== this.levelFilter.value);
    });
  }

  private refresh(): void {
    this.filteredLessons = this.filter(this.lessons);
  }

  setLevelFilter(filter: Segment): void {
    if (filter === this.levelFilter) {
      this.levelFilter = this.dictionary.allLessonFilter as Segment;
    } else {
      this.levelFilter = filter;
    }
    this.refresh();
  }

  setDurationFilter(filter: Segment): void {
    if (filter === this.durationFilter) {
      this.durationFilter = this.dictionary.allLessonFilter as Segment;
    } else {
      this.durationFilter = filter;
    }
    this.refresh();
  }

  subscribe(): void {
    this.subscribePackage.emit();
  }

  selectLessonHandler(lesson: Lesson): void {
    this.selectLesson.emit(lesson);
  }
}
