import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscribeFormPricesComponent } from './subscribe-form-prices.component';
import { PriceOptionModule } from '../../ui/prices/price-option/price-option.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PriceOptionsModule } from '../../ui/prices/price-options/price-options.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [SubscribeFormPricesComponent],
  imports: [CommonModule, PriceOptionModule, FlexLayoutModule, PriceOptionsModule, TranslateModule],
  exports: [SubscribeFormPricesComponent],
})
export class SubscribeFormPricesModule {}
