import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from '../../dialog/dialog.module';
import { PricesPaywallModule } from '../../../../../pages/personalized-program/components/prices-paywall/prices-paywall.module';
import { PricesPaywallExtraModule } from '../../../../../pages/personalized-program/components/prices-paywall-extra/prices-paywall-extra.module';
import { PricesVerticalExtraModule } from '../../../../../pages/personalized-program/components/prices-vertical-extra/prices-vertical-extra.module';
import { PricesPaywallUpsellModule } from '../../../../../pages/personalized-program/components/prices-paywall-upsell/prices-paywall-upsell.module';
import { LoadingModule } from '../../loading/loading.module';
import { UpSellWorkshopModalComponent } from './up-sell-workshop-modal.component';
import { NgxPayPalModule } from 'ngx-paypal';
import { PaypalWorkshopModule } from '../../../paypal-workshop/paypal-workshop.module';
import { PaypalModule } from '../../../paypal/paypal.module';
import { TranslateModule } from '@ngx-translate/core';
import { InterpolateDirective } from '../../../../../core/directives/interpolation/interpolate.directive';

@NgModule({
  declarations: [UpSellWorkshopModalComponent],
  imports: [
    CommonModule,
    DialogModule,
    PricesPaywallModule,
    PricesPaywallExtraModule,
    PricesVerticalExtraModule,
    PricesPaywallUpsellModule,
    LoadingModule,
    NgxPayPalModule,
    PaypalWorkshopModule,
    PaypalModule,
    TranslateModule,
    InterpolateDirective,
  ],
  exports: [UpSellWorkshopModalComponent],
})
export class UpSellWorkshopModalModule {}
