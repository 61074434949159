import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddCartModalComponent } from './add-cart-modal.component';
import { DialogModule } from '../../../../../shared/components/ui/dialog/dialog.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CustomDirectiveModule } from '../../../../../shared/directives/custom-layout-directive/custom-flex-directive.module';
import { StripePaymentFormModule } from '../../../../../shared/components/stripe-payment-form/stripe-payment-form.module';
import { LoadingModule } from '../../../../../shared/components/ui/loading/loading.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [AddCartModalComponent],
  exports: [AddCartModalComponent],
  imports: [
    CommonModule,
    DialogModule,
    FlexLayoutModule,
    CustomDirectiveModule,
    StripePaymentFormModule,
    LoadingModule,
    TranslateModule,
  ],
})
export class AddCartModalModule {}
