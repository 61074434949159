import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Dialog } from '../../../../../dataset/Dialog';
import { IDialog } from '../interfaces/IDialog';

@Component({
  templateUrl: './confirm-email-subscription-modal.component.html',
  styleUrls: ['./confirm-email-subscription-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmEmailSubscriptionModalComponent extends Dialog implements IDialog {
  constructor() {
    super();
  }

  closeModal(result: boolean): void {
    this.resolve(result);
  }
}
