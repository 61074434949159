import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class SaleGuard {
  async canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const path = state.url.replace('/sale?', '').replace('/sale', '');

    const redirect = `${window.location.origin}/app/packages?action=upgrade`;

    window.location.href = path ? `${redirect}&${path}` : redirect;
    return false;
  }
}
