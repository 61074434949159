import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SaleModalComponent } from './sale-modal.component';
import { DialogModule } from '../../dialog/dialog.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { InterpolateDirective } from '../../../../../core/directives/interpolation/interpolate.directive';

@NgModule({
  declarations: [SaleModalComponent],
  imports: [CommonModule, DialogModule, FlexLayoutModule, TranslateModule, InterpolateDirective],
  exports: [SaleModalComponent],
})
export class SaleModalModule {}
