import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GiftCertificateRoutingModule } from './gift-certificate-routing.module';
import { GiftComponent } from './gift.component';
import { DashboardHeaderModule } from '../../layout/dashboard-header/dashboard-header.module';
import { DashboardFooterModule } from '../../layout/dashboard-footer/dashboard-footer.module';
import { LoadingModule } from '../../shared/components/ui/loading/loading.module';
import { GiftCertificatesService } from './services/gift-certificates.service';

@NgModule({
  imports: [
    CommonModule,
    GiftCertificateRoutingModule,
    DashboardHeaderModule,
    DashboardFooterModule,
    LoadingModule,
  ],
  declarations: [GiftComponent],
  providers: [GiftCertificatesService],
})
export class GiftCertificateModule {}
