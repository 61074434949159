import { Injectable } from '@angular/core';
import { PricesContextService } from '../../context/prices-context/prices-context.service';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CertificatesApiService } from '../../api/certificates-api/certificates-api.service';
import { CertificatePrice } from '../../dataset/CertificatePrice';

@Injectable()
export class CertPricesResolveService {
  constructor(
    private certificatesApiService: CertificatesApiService,
    private pricesContext: PricesContextService
  ) {}

  resolve(): Observable<CertificatePrice[]> | void {
    if (this.pricesContext.getCertificatePrices()) {
      return;
    }
    return this.certificatesApiService
      .getPrices()
      .pipe(tap(prices => this.pricesContext.nextCertificatePrices(prices)));
  }
}
