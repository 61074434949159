import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { AuthGuard } from '../guards/auth-guard/auth-guard.service';
import { UserResolver } from '../resolvers/user-resolver/user-resolve.service';
import { PackagesResolver } from '../resolvers/packages-resolver/packages-resolve.service';
import { LessonResolver } from '../resolvers/lesson-resolver/lesson-resolve.service';
import { OptionalAuthGuard } from './packages/guards/optional-auth-guard/optional-auth-guard';
import { ChallengesResolver } from '../resolvers/challenges-resolver/challenges-resolve.service';
import { PackageLessonsResolver } from '../resolvers/package-lessons-resolver/package-lessons-resolve.service';
import { PricesResolver } from '../resolvers/prices-resolver/prices-resolve.service';
import { DailyLessonResolver } from '../resolvers/daily-lesson-resolver/daily-lesson.resolver';
import { NewClassesResolver } from '../resolvers/new-classes-resolver/new-classes.resolver';
import { ActualChallengesResolver } from '../resolvers/actual-challenges-resolver/actual-challenges.resolver';
import { ProgressPhotoResolver } from '../resolvers/progress-photo-resolver/progress-photo.resolver';
import { SubscriptionsResolver } from '../resolvers/subscription-resolver/subscriptions.resolver';
import { AvailableClassesResolver } from '../resolvers/available-classes/available-classes.resolver';
import { FreeClassesResolver } from '../resolvers/free-classes/free-classes.resolver';
import { FavoriteClassesResolver } from '../resolvers/favorite-classes-resolver/favorite-classes-resolver';
import { AccessTokenGuard } from '../guards/access-token-quard/access-token.guard';

const dashboardRoutes: Routes = [
  {
    path: '',
    redirectTo: '/app/home',
    pathMatch: 'full',
  },
  {
    path: '',
    component: DashboardComponent,
    resolve: {
      user: UserResolver,
      packages: PackagesResolver,
      prices: PricesResolver,
    },
    data: {
      disableSalePopup: true,
    },
    children: [
      {
        path: 'influencer_flow/2',
        loadChildren: () => import('./redeem/redeem.module').then(m => m.RedeemModule),
        data: {
          prefix: 'influencer-flow-2',
        },
      },
      {
        path: 'influencer_flow/3',
        loadChildren: () => import('./redeem/redeem.module').then(m => m.RedeemModule),
        data: {
          variant: 'bloggers3',
          prefix: 'influencer-flow-3',
        },
      },
    ],
  },
  {
    path: 'app',
    component: DashboardComponent,
    canActivate: [AccessTokenGuard],
    resolve: {
      user: UserResolver,
      packages: PackagesResolver,
      prices: PricesResolver,
      subscriptions: SubscriptionsResolver,
    },
    children: [
      {
        path: 'sales/popup',
        redirectTo: '/app/packages?action=upgrade',
      },
      {
        path: 'challenges',
        loadChildren: () =>
          import('../dashboard/challenges/challenges.module').then(m => m.ChallengesModule),
        canActivate: [OptionalAuthGuard],
        resolve: {
          challenges: ChallengesResolver,
        },
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('../dashboard/profile/profile.module').then(m => m.ProfileModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'available-classes',
        loadChildren: () =>
          import('../dashboard/free-classes/free-classes.module').then(m => m.FreeClassesModule),
        resolve: {
          availableClasses: AvailableClassesResolver,
        },
      },
      {
        path: 'free-classes',
        loadChildren: () =>
          import('../dashboard/free-classes/free-classes.module').then(m => m.FreeClassesModule),
        resolve: {
          freeClasses: FreeClassesResolver,
        },
      },
      {
        path: 'new-classes',
        loadChildren: () =>
          import('../dashboard/new-classes/new-classes.module').then(m => m.NewClassesModule),
        resolve: {
          newClasses: NewClassesResolver,
        },
      },
      {
        path: 'favorite-classes',
        loadChildren: () =>
          import('../dashboard/favorite-classes/favorite-classes.module').then(
            m => m.FavoriteClassesModule
          ),
        resolve: {
          availableClasses: FavoriteClassesResolver,
        },
      },
      {
        path: 'progress-photos',
        loadChildren: () => import('../dashboard/photos/photos.module').then(m => m.PhotosModule),
        canActivate: [AuthGuard],
        resolve: {
          progressPhoto: ProgressPhotoResolver,
        },
      },
      {
        path: 'packages',
        loadChildren: () =>
          import('../dashboard/packages/components/packages/packages.module').then(
            m => m.PackagesViewModule
          ),
        canActivate: [OptionalAuthGuard],
        resolve: {
          actualChallenges: ActualChallengesResolver,
        },
      },
      {
        path: 'packages/:package_id',
        loadChildren: () =>
          import('../dashboard/packages/components/package/package.module').then(
            m => m.PackageModule
          ),
        resolve: {
          lessons: PackageLessonsResolver,
        },
      },
      {
        path: 'packages/:package_id/about',
        loadChildren: () =>
          import('../dashboard/packages/components/package-about/package-about.module').then(
            m => m.PackageAboutModule
          ),
      },
      {
        path: 'packages/:package_id/lesson/:lesson_id',
        loadChildren: () =>
          import('../dashboard/packages/components/lesson/lesson.module').then(m => m.LessonModule),
        resolve: {
          lessons: PackageLessonsResolver,
          lesson: LessonResolver,
        },
      },
      {
        path: 'home',
        loadChildren: () => import('../dashboard/home/home.module').then(m => m.HomeModule),
        resolve: {
          progressPhoto: ProgressPhotoResolver,
          daily: DailyLessonResolver,
          newClasses: NewClassesResolver,
          favoriteClasses: FavoriteClassesResolver,
          actualChallenges: ActualChallengesResolver,
          challenges: ChallengesResolver,
        },
      },
      {
        path: 'page/:page',
        loadChildren: () => import('../layout/page/page.module').then(m => m.PageModule),
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard],
      },
      {
        path: 'activity',
        loadChildren: () => import('./activities/activities.module').then(m => m.ActivitiesModule),
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'redeem',
    loadChildren: () => import('./redeem/redeem.module').then(m => m.RedeemModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(dashboardRoutes)],
  exports: [RouterModule],
  providers: [
    UserResolver,
    PackagesResolver,
    OptionalAuthGuard,
    ChallengesResolver,
    PackageLessonsResolver,
    AuthGuard,
    LessonResolver,
    AvailableClassesResolver,
    DailyLessonResolver,
    NewClassesResolver,
    FreeClassesResolver,
    ActualChallengesResolver,
    ProgressPhotoResolver,
    FavoriteClassesResolver,
    AccessTokenGuard,
  ],
})
export class DashboardRoutingModule {}
