<header>
  <h2>Choose your class</h2>
  <div class="packages layout-row">
    <div
      class="package flex"
      *ngFor="let pack of packages"
      role="presentation"
      (click)="selectPackage(pack)"
      [class.active]="pack === selectedPackage">
      <img
        [src]="pack.images.s3_16x9_320"
        alt="" />

      <h1>{{ pack.name | translate }}</h1>
    </div>
  </div>
</header>
