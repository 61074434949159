import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LessonDoneComponent } from './lesson-done.component';
import { ParseDatePipeModule } from '../../../../../../shared/pipes/parse-date-pipe/parse-date-pipe.module';
import { DialogModule } from '../../../../../../shared/components/ui/dialog/dialog.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CustomDirectiveModule } from '../../../../../../shared/directives/custom-layout-directive/custom-flex-directive.module';
import { TranslateModule } from '@ngx-translate/core';
import { InterpolateDirective } from '../../../../../../core/directives/interpolation/interpolate.directive';

@NgModule({
  declarations: [LessonDoneComponent],
  imports: [
    CommonModule,
    ParseDatePipeModule,
    DialogModule,
    FlexLayoutModule,
    CustomDirectiveModule,
    TranslateModule,
    InterpolateDirective,
  ],
  exports: [LessonDoneComponent],
})
export class LessonDoneModule {}
