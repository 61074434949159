<app-dialog-container
  [state]="state"
  [classNames]="'without-padding standard white-close'"
  (close)="closeModal()">
  <img
    src="{{ assets }}/images/{{ theme }}/sale-title.webp"
    srcset="
      {{assets}}/images/{{theme}}/sale-title.webp    1x,
      {{assets}}/images/{{theme}}/sale-title@2x.webp 2x
    "
    alt="Sale header"
    class="sale-modal-banner desktop" />

  <img
    src="{{ assets }}/images/{{ theme }}/sale-title-mobile.webp"
    srcset="
      {{assets}}/images/{{theme}}/sale-title-mobile.webp    1x,
      {{assets}}/images/{{theme}}/sale-title-mobile@2x.webp 2x
    "
    alt="Sale header"
    class="sale-modal-banner mobile" />

  <div class="sale text-center p10-res">
    <div>
      <div
        fxLayout="column"
        fxLayoutAlign="start center"
        fxLayoutGap="20px">
        <div
          fxLayout="column"
          fxLayoutGap="10px">
          <h2 class="st-h2"> {{ 'Get 1-year access to STRETCHIT' | translate }} </h2>

          <div
            fxLayout="column"
            fxLayoutGap="5px">
            <p
              class="st-p1"
              *ngIf="currency === 'USD'">
              <span
                [stInterpolate]="'for just [price]' | translate"
                [params]="{ price: ' ' }"></span>
              <strong class="sale-price">$144.99</strong>
              / {{ 'year' | translate }}
            </p>

            <p
              class="st-p1"
              *ngIf="currency !== 'USD'">
              <span
                [stInterpolate]="'for just [price]' | translate"
                [params]="{ price: ' ' }"></span>
              <strong class="sale-price">
                {{ '144.99' | currency: currency : 'symbol-narrow' }}
              </strong>
              / {{ 'year' | translate }}
            </p>

            <p class="st-p1 st-color-dark-grey">
              ({{ '40% off the monthly price' | translate }})
            </p>
          </div>
        </div>

        <!--        <span class="offer"> {{ 'Offer ends: September 2' | translate }} </span>-->
        <span class="offer"> {{ 'Final Hours' | translate }} </span>
      </div>
    </div>

    <div class="sale-modal-bottom">
      <div
        fxLayout="column"
        fxLayoutGap="20px">
        <button
          class="st-btn st-btn-dark st-weight-700 st-w-fill st-center"
          (click)="buyNow()">
          {{ 'Buy now' | translate }}
        </button>

        <p
          class="st-p1 st-color-dark-grey text-center"
          [stInterpolate]="
            '*All prices are in [currency]. Your billing date will change and you’ll get a refund for an unused portion of your current subscription(s).'
              | translate
          "
          [params]="{ currency }">
        </p>
      </div>
    </div>
  </div>
</app-dialog-container>
