import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscribeFormComponent } from './subscribe-form.component';
import { PaymentMethodSelectorModule } from '../../payment-method-selector/payment-method-selector.module';
import { SubscribeFormHeaderModule } from '../subscribe-form-header/subscribe-form-header.module';
import { SubscribeFormDescriptionModule } from '../subscribe-form-description/subscribe-form-description.module';
import { SubscribeFormPricesModule } from '../subscribe-form-prices/subscribe-form-prices.module';
import { SubscribeFormAdjustmentModule } from '../subscribe-form-adjustment/subscribe-form-adjustment.module';
import { SubscribeFormSubtitleModule } from '../subscribe-form-subtitle/subscribe-form-subtitle.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CustomDirectiveModule } from '../../../directives/custom-layout-directive/custom-flex-directive.module';
import { PricesModule } from '../../../../auth/sign-up/prices/prices.module';
import { PaymentSystemsModule } from '../../payment-systems/payment-systems.module';
import { PromoCodeModule } from '../../../../core/components/promo-code/promo-code.module';

@NgModule({
  declarations: [SubscribeFormComponent],
  imports: [
    CommonModule,
    PaymentMethodSelectorModule,
    SubscribeFormHeaderModule,
    SubscribeFormDescriptionModule,
    SubscribeFormPricesModule,
    SubscribeFormAdjustmentModule,
    SubscribeFormSubtitleModule,
    FlexLayoutModule,
    CustomDirectiveModule,
    PricesModule,
    PaymentSystemsModule,
    PromoCodeModule,
  ],
  exports: [SubscribeFormComponent],
})
export class SubscribeFormModule {}
