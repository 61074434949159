<app-dialog-container
  (close)="closeModal()"
  [state]="state"
  [classNames]="'no-padding big'">
  <ng-container *ngIf="initialized">
    <ng-container *ngIf="!lesson">
      <app-add-header
        (select)="selectPackage($event, true)"
        [selectedPackage]="selectedPackage"
        [packages]="packages" />

      <app-add-lesson-filter
        [user]="user"
        (subscribePackage)="subscribe()"
        (selectLesson)="selectLesson($event)"
        [selectedPackage]="selectedPackage"
        [lessons]="lessons" />
    </ng-container>

    <ng-container *ngIf="availableToAdd && lesson && selectedPackage">
      <app-add-selected-lesson
        [lesson]="lesson"
        (reset)="resetSelectedLesson()"
        [selectedPackage]="selectedPackage"
        [title]="data.title" />

      <app-add-date-filter [selectedDate]="data.date" />

      <div class="button-container text-center">
        <button
          class="button blue"
          (click)="data.edit ? updateEvent() : addToSchedule()">
          {{ (data.edit ? 'Update an event' : 'Add to schedule') | translate }}
        </button>
      </div>
    </ng-container>
  </ng-container>
</app-dialog-container>
