<app-dialog-container
  [state]="state"
  [classNames]="'no-padding'"
  (close)="closeModal()">
  <div class="info">
    <h1>{{ 'Important' | translate }}</h1>

    <div class="text text-center">
      {{
        'You’ve been granted free access to STRETCHIT. Please cancel your subscription with [Apple/Google] to avoid further charges. [Apple/Google] manages those payments and we don’t have access to their processing system'
          | translate
      }}
    </div>

    <div class="flex">
      <button
        class="button dark block"
        (click)="resolve(true)">
        {{ 'OK' | translate }}
      </button>
    </div>
  </div>
</app-dialog-container>
