import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageContextService } from '../../context/storage-context/storage-context.service';

@Injectable()
export class WelcomeGuard {
  constructor(
    private router: Router,
    private storageContext: StorageContextService
  ) {}

  async canActivate(): Promise<boolean> {
    !this.storageContext.getItem('access_token')
      ? await this.router.navigate(['/auth/sign-up/yearly'])
      : await this.router.navigate(['/app/home']);
    return false;
  }
}
