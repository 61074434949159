import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SignUpComponent } from './sign-up/sign-up.component';

export type ComponentType = 'other' | 'sign-up' | 'home';

@UntilDestroy()
@Component({
  selector: 'auth-component',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthComponent {
  componentType: ComponentType;

  routerActivate(component: SignUpComponent | undefined): void {
    this.componentType = component instanceof SignUpComponent ? 'sign-up' : 'other';
  }
}
