<app-dialog-container
  [state]="state"
  [classNames]="'big no-padding'"
  [disableClose]="true">
  <div class="info">
    <h1 class="text-center section-title">
      {{ 'Let’s Stay in Touch' | translate }}
    </h1>

    <div class="text text-center">
      {{
        'Would you like to receive emails with training tips, community activities and special offers?'
          | translate
      }}
    </div>

    <div class="layout-row layout-justify-center padding-15">
      <div class="flex auto">
        <button
          class="button default"
          (click)="closeModal(false)">
          {{ 'No' | translate }}
        </button>
      </div>

      <div class="flex auto">
        <button
          class="button dark"
          (click)="closeModal(true)">
          {{ 'Yes' | translate }}
        </button>
      </div>
    </div>
  </div>
</app-dialog-container>
