import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddToScheduleModalComponent } from './add-to-schedule-modal.component';
import { DialogModule } from '../../dialog/dialog.module';
import { LessonItemModule } from '../../../lesson-item/lesson-item.module';
import { DateInputModule } from '../../date-input/date-input.module';
import { DropdownModule } from '../../dropdown/dropdown.module';
import { AddSelectedLessonModule } from './add-selected-lesson/add-selected-lesson.module';
import { AddDateFilterModule } from './add-date-filter/add-date-filter.module';
import { AddLessonFilterModule } from './add-lesson-filter/add-lesson-filter.module';
import { AddHeaderModule } from './add-header/add-header.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [AddToScheduleModalComponent],
  imports: [
    CommonModule,
    DialogModule,
    LessonItemModule,
    DateInputModule,
    DropdownModule,
    AddSelectedLessonModule,
    AddDateFilterModule,
    AddLessonFilterModule,
    AddHeaderModule,
    TranslateModule,
  ],
  exports: [AddToScheduleModalComponent],
})
export class AddToScheduleModalModule {}
