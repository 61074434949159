import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProgressCases } from '../../dataset/ProgressCases';
import { ProgressApiService } from '../../api/progress-api/progress-api.service';
import { tap } from 'rxjs/operators';
import { IContext } from '../interfaces/IContext';

@Injectable()
export class ProgressCasesContextService implements IContext<ProgressCases[] | null> {
  private cases = new BehaviorSubject<ProgressCases[] | null>(null);

  constructor(private progressApiService: ProgressApiService) {}

  get$(): Observable<ProgressCases[] | null> {
    return this.cases.asObservable();
  }

  get(): ProgressCases[] | null {
    return this.cases.getValue();
  }

  next(value: ProgressCases[] | null): void {
    this.cases.next(value);
  }

  clear(): void {
    this.cases.next(null);
  }

  retrieve(): Observable<ProgressCases[] | null> {
    return this.progressApiService.getCases().pipe(tap(cases => this.cases.next(cases)));
  }
}
