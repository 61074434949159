import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnterEmailComponent } from './enter-email.component';
import { DialogModule } from '../../dialog/dialog.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { LoadingModule } from '../../loading/loading.module';
import { StFormFieldModule } from '../../st-form-field/st-form-field.module';
import { TranslateModule } from '@ngx-translate/core';
import { CustomDirectiveModule } from '../../../../directives/custom-layout-directive/custom-flex-directive.module';
import { InterpolateDirective } from '../../../../../core/directives/interpolation/interpolate.directive';

@NgModule({
  declarations: [EnterEmailComponent],
  imports: [
    CommonModule,
    DialogModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    LoadingModule,
    StFormFieldModule,
    TranslateModule,
    CustomDirectiveModule,
    InterpolateDirective,
  ],
  exports: [EnterEmailComponent],
})
export class EnterEmailModule {}
