import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaloriesModalComponent } from './calories-modal.component';
import { DialogModule } from '../../dialog/dialog.module';
import { DropdownModule } from '../../dropdown/dropdown.module';
import { FormsModule } from '@angular/forms';
import { UnitsService } from '../../../../../services/units/units.service';
import { GenderDropdownModule } from '../../gender-dropdown/gender-dropdown.module';
import { DateInputModule } from '../../date-input/date-input.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [CaloriesModalComponent],
  imports: [
    CommonModule,
    DialogModule,
    DropdownModule,
    FormsModule,
    GenderDropdownModule,
    DateInputModule,
    TranslateModule,
  ],
  exports: [CaloriesModalComponent],
  providers: [UnitsService],
})
export class CaloriesModalModule {}
