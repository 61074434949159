import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChallengeFormComponent } from './challenge-form.component';
import { PackageThumbnailModule } from '../package-thumbnail/package-thumbnail.module';
import { PriceOptionModule } from '../ui/prices/price-option/price-option.module';
import { PricesModule } from '../../../auth/sign-up/prices/prices.module';
import { PaymentMethodSelectorModule } from '../payment-method-selector/payment-method-selector.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CustomDirectiveModule } from '../../directives/custom-layout-directive/custom-flex-directive.module';
import { PaymentSystemsModule } from '../payment-systems/payment-systems.module';
import { PromoCodeModule } from '../../../core/components/promo-code/promo-code.module';

@NgModule({
  declarations: [ChallengeFormComponent],
  imports: [
    CommonModule,
    PackageThumbnailModule,
    PriceOptionModule,
    PricesModule,
    PaymentMethodSelectorModule,
    FlexLayoutModule,
    CustomDirectiveModule,
    PaymentSystemsModule,
    PromoCodeModule,
  ],
  exports: [ChallengeFormComponent],
})
export class ChallengeFormModule {}
