import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelpPlanComponent } from './help-plan.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CustomDirectiveModule } from '../../../../../../shared/directives/custom-layout-directive/custom-flex-directive.module';
import { PricesModule } from '../../../../../../auth/sign-up/prices/prices.module';
import { PromoCodeModule } from '../../../../../../core/components/promo-code/promo-code.module';
import { PaymentFormModule } from '../../../../../../auth/sign-up/payment-form/payment-form.module';
import { PaymentSystemsModule } from '../../../../../../shared/components/payment-systems/payment-systems.module';
import { PaymentMethodSelectorModule } from '../../../../../../shared/components/payment-method-selector/payment-method-selector.module';
import { SubscribeFormAdjustmentModule } from '../../../../../../shared/components/subscribe/subscribe-form-adjustment/subscribe-form-adjustment.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [HelpPlanComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    CustomDirectiveModule,
    PricesModule,
    PromoCodeModule,
    PaymentFormModule,
    PaymentSystemsModule,
    PaymentMethodSelectorModule,
    SubscribeFormAdjustmentModule,
    TranslateModule,
  ],
  exports: [HelpPlanComponent],
})
export class HelpPlanModule {}
