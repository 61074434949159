import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Api } from '../extends/api';
import { IApi } from '../../core/interrfaces/IApi';
import { CertificatePrice } from '../../dataset/CertificatePrice';
import { Observable } from 'rxjs';
import { COMMON_BASE_URL } from '../../core/tokens/common-base-url.token';

@Injectable()
export class CertificatesApiService extends Api implements IApi {
  constructor(
    private http: HttpClient,
    @Inject(COMMON_BASE_URL) private baseUrl: string
  ) {
    super();
  }

  getPrices(): Observable<CertificatePrice[]> {
    return this.http.get<CertificatePrice[]>(this.buildBaseUrl('api/gift_certificates/available'));
  }

  buildBaseUrl(input: string): string {
    return this.baseUrl + '/' + input;
  }
}
