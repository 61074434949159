import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Lesson } from '../../../../../../dataset/Lesson';
import { Package } from '../../../../../../dataset/Package';

@Component({
  selector: 'app-add-selected-lesson',
  templateUrl: './add-selected-lesson.component.html',
  styleUrls: ['./add-selected-lesson.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddSelectedLessonComponent {
  @Input() lesson: Lesson;
  @Input() title: string;
  @Input() selectedPackage: Package;

  @Output() reset = new EventEmitter<void>();

  resetHandler(): void {
    this.reset.emit();
  }
}
