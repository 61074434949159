import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageContextService } from '../../../context/storage-context/storage-context.service';

@Injectable()
export class AuthorizationGuard {
  constructor(
    private storageContext: StorageContextService,
    private router: Router
  ) {}

  async canActivate(): Promise<boolean> {
    if (this.storageContext.getItem('access_token')) {
      await this.router.navigateByUrl('/app/home');
      return false;
    }
    return true;
  }
}
