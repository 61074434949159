import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RestDayModalComponent } from './rest-day-modal.component';
import { DialogModule } from '../../dialog/dialog.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CustomDirectiveModule } from '../../../../directives/custom-layout-directive/custom-flex-directive.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [RestDayModalComponent],
  imports: [CommonModule, DialogModule, FlexLayoutModule, CustomDirectiveModule, TranslateModule],
  exports: [RestDayModalComponent],
})
export class RestDayModalModule {}
