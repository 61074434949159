import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Package } from '../../../../../../dataset/Package';

@UntilDestroy()
@Component({
  selector: 'app-add-header',
  templateUrl: './add-header.component.html',
  styleUrls: ['./add-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddHeaderComponent {
  @Input() packages: Package[];
  @Input() selectedPackage: Package | undefined;

  @Output() select = new EventEmitter<Package>();

  selectPackage(pack: Package): void {
    this.select.emit(pack);
  }
}
