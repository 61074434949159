import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddLessonFilterComponent } from './add-lesson-filter.component';
import { SegmentControlModule } from '../../../segment-control/segment-control.module';
import { LessonsGridModule } from '../../../../lessons-grid/lessons-grid.module';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [AddLessonFilterComponent],
  imports: [CommonModule, SegmentControlModule, LessonsGridModule, FlexLayoutModule],
  exports: [AddLessonFilterComponent],
})
export class AddLessonFilterModule {}
