import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagesApiService } from './pages-api/pages-api.service';
import { AuthApiService } from './auth-api/auth-api.service';
import { UserApiService } from './user-api/user-api.service';
import { ProgressApiService } from './progress-api/progress-api.service';
import { PackagesApiService } from './packages-api/packages-api.service';
import { PricesApiService } from './prices-api/prices-api.service';
import { CouponApiService } from './coupon-api/coupon-api.service';
import { EventsApiService } from './events-api/events-api.service';
import { ChallengesApiService } from './challenges-api/challenges-api.service';
import { MediaApiService } from './media-api/media-api.service';
import { AchievementsApiService } from './achievements-api/achievements-api.service';
import { LessonsApiService } from './lessons-api/lessons-api.service';
import { RecommendationsApiService } from './recommendations-api/recommendations-api.service';
import { CardsApiService } from './cards-api/cards-api.service';
import { SubscriptionApiService } from './subscription-api/subscription-api.service';
import { CertificatesApiService } from './certificates-api/certificates-api.service';
import { GeoApiService } from './geo-api/geo-api.service';
import { RunwayerApiService } from './runwayer-api/runwayer-api.service';
import { ChargebeeApiService } from './chargebee-api/chargebee-api.service';
import { LessonGroupProgramsApiService } from './lesson-group-programs-api/lesson-group-programs-api.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    PagesApiService,
    AuthApiService,
    UserApiService,
    ProgressApiService,
    PackagesApiService,
    PricesApiService,
    CouponApiService,
    EventsApiService,
    ChallengesApiService,
    MediaApiService,
    AchievementsApiService,
    LessonGroupProgramsApiService,
    LessonsApiService,
    RecommendationsApiService,
    CardsApiService,
    SubscriptionApiService,
    CertificatesApiService,
    RunwayerApiService,
    GeoApiService,
    ChargebeeApiService,
  ],
})
export class ApiModule {}
