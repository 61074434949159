import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecommendedPackageComponent } from './recommended-package.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LessonPropertiesModule } from '../../../../../../shared/components/lesson-properties/lesson-properties.module';
import { EllipsisModule } from 'ngx-ellipsis';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [RecommendedPackageComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    LessonPropertiesModule,
    EllipsisModule,
    TranslateModule,
  ],
  exports: [RecommendedPackageComponent],
})
export class RecommendedPackageModule {}
