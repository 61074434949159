import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from '../../dialog/dialog.module';
import { DidYouKnowModalComponent } from './did-you-know-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { InterpolateDirective } from '../../../../../core/directives/interpolation/interpolate.directive';

@NgModule({
  declarations: [DidYouKnowModalComponent],
  imports: [CommonModule, DialogModule, TranslateModule, InterpolateDirective],
  exports: [DidYouKnowModalComponent],
})
export class DidYouKnowModalModule {}
