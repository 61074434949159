@if (loading || localeService.reloading()) {
  <div class="loading">
    <app-loading />
    <app-loading-logo />
  </div>
}

<div
  #dialogContainer
  style="height: 0">
  <app-sprite-svg></app-sprite-svg>
</div>

<div
  #notificationContainer
  style="height: 0">
</div>

<router-outlet></router-outlet>

@if (!cookieNoticeDisabled) {
  <app-cookie-notice (disableCookieNotice)="disableCookieNotice()" />
}
