import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LessonsApiService } from '../../api/lessons-api/lessons-api.service';
import { Lesson } from '../../dataset/Lesson';
import { filter, tap } from 'rxjs/operators';
import { LessonsContextService } from '../../context/lessons-context/lessons-context.service';

@Injectable()
export class NewClassesResolver {
  constructor(
    private lessonsContext: LessonsContextService,
    private lessonsApiService: LessonsApiService
  ) {}

  resolve(): Observable<Lesson[]> | void {
    const newLessons = this.lessonsContext.getNewLessons();

    if (newLessons && newLessons.length) {
      return;
    }

    return this.lessonsApiService.getNewLessons().pipe(
      filter(lessons => !!lessons),
      tap(lessons => this.lessonsContext.nextNewLessons(lessons))
    );
  }
}
