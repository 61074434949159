import { Injectable } from '@angular/core';
import { IContext } from '../interfaces/IContext';
import { ScheduledEvent } from '../../dataset/ScheduledEvent';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class EventsContextService implements IContext<ScheduledEvent[] | null> {
  private events = new BehaviorSubject<ScheduledEvent[] | null>(null);

  clear(): void {
    this.events.next(null);
  }

  get() {
    return this.events.getValue();
  }

  get$() {
    return this.events.asObservable();
  }

  next(value: ScheduledEvent[]): void {
    this.events.next(value);
  }
}
