<ng-container *ngIf="giftCertificatesService.loading$ | async">
  <app-loading />

  <div class="loading"></div>
</ng-container>

<div class="wrapper">
  <div class="content">
    <dashboard-header [componentType]="'home'" />
    <main class="gift-certificate">
      <router-outlet />
    </main>
  </div>

  <div class="other">
    <dashboard-footer />
  </div>
</div>
