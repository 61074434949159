import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Media } from '../../dataset/Media';
import { filter, tap } from 'rxjs/operators';
import { MediaContextService } from '../../context/media-context/media-context.service';
import { MediaApiService } from '../../api/media-api/media-api.service';
import { UserContextService } from '../../context/user-context/user-context.service';

@Injectable()
export class ProgressPhotoResolver {
  constructor(
    private userContext: UserContextService,
    private mediaApiService: MediaApiService,
    private mediaContext: MediaContextService
  ) {}

  resolve(): Observable<Media[]> | void {
    const photos = this.mediaContext.get();
    const user = this.userContext.get();

    if ((photos && photos.length) || !user) {
      return;
    }

    return this.mediaApiService.retrieve().pipe(
      filter(media => !!media),
      tap(media => this.mediaContext.next(media))
    );
  }
}
