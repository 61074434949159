import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProgressCases } from '../../dataset/ProgressCases';
import { Observable } from 'rxjs';
import { IApi } from '../../core/interrfaces/IApi';
import { Api } from '../extends/api';
import { COMMON_BASE_URL } from '../../core/tokens/common-base-url.token';

@Injectable()
export class ProgressApiService extends Api implements IApi {
  constructor(
    private http: HttpClient,
    @Inject(COMMON_BASE_URL) private baseUrl: string
  ) {
    super();
  }

  getCases(): Observable<ProgressCases[]> {
    return this.http.get<ProgressCases[]>(this.buildBaseUrl(`api/progress_cases`));
  }

  buildBaseUrl(input: string): string {
    return this.baseUrl + '/' + input;
  }
}
