import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChoosePlanModalComponent } from './choose-plan-modal.component';
import { DialogModule } from '../../dialog/dialog.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { LoadingModule } from '../../loading/loading.module';
import { StFormFieldModule } from '../../st-form-field/st-form-field.module';
import { TranslateModule } from '@ngx-translate/core';
import { CustomDirectiveModule } from '../../../../directives/custom-layout-directive/custom-flex-directive.module';
import { RouterLink } from '@angular/router';
import { ShowHideCheckboxModule } from '../../show-hide-checkbox/show-hide-checkbox.module';
import { PricesModule } from '../../../../../auth/sign-up/prices/prices.module';

@NgModule({
  declarations: [ChoosePlanModalComponent],
  imports: [
    CommonModule,
    DialogModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    LoadingModule,
    StFormFieldModule,
    TranslateModule,
    CustomDirectiveModule,
    RouterLink,
    ShowHideCheckboxModule,
    PricesModule,
  ],
  exports: [ChoosePlanModalComponent],
})
export class ChoosePlanModalModule {}
