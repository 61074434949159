import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelpWelcomeComponent } from './help-welcome.component';
import { SignInFromModule } from '../../../../../../auth/sign-in/sign-in-from/sign-in-from.module';

@NgModule({
  declarations: [HelpWelcomeComponent],
  exports: [HelpWelcomeComponent],
  imports: [CommonModule, SignInFromModule],
})
export class HelpWelcomeModule {}
