import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmEmailSubscriptionModalComponent } from './confirm-email-subscription-modal.component';
import { DialogModule } from '../../dialog/dialog.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ConfirmEmailSubscriptionModalComponent],
  imports: [CommonModule, DialogModule, TranslateModule],
  exports: [ConfirmEmailSubscriptionModalComponent],
})
export class ConfirmEmailSubscriptionModalModule {}
