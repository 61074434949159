import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CropperModalComponent } from './cropper-modal.component';
import { DialogModule } from '../../dialog/dialog.module';
import { ImageCropperModule } from 'ngx-image-cropper';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [CropperModalComponent],
  imports: [CommonModule, DialogModule, ImageCropperModule, FlexLayoutModule, TranslateModule],
  exports: [CropperModalComponent],
})
export class CropperModalModule {}
