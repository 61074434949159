import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BuyModalComponent } from './buy-modal.component';
import { DialogModule } from '../../dialog/dialog.module';
import { LoadingModule } from '../../loading/loading.module';
import { PaymentMethodSelectorModule } from '../../../payment-method-selector/payment-method-selector.module';
import { BuyHeaderModule } from './buy-header/buy-header.module';
import { FlexModule } from '@angular/flex-layout';
import { PricesModule } from '../../../../../auth/sign-up/prices/prices.module';
import { PaymentSystemsModule } from '../../../payment-systems/payment-systems.module';
import { PromoCodeModule } from '../../../../../core/components/promo-code/promo-code.module';
import { SubscribeFormAdjustmentModule } from '../../../subscribe/subscribe-form-adjustment/subscribe-form-adjustment.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [BuyModalComponent],
  imports: [
    CommonModule,
    DialogModule,
    LoadingModule,
    PaymentMethodSelectorModule,
    BuyHeaderModule,
    FlexModule,
    PricesModule,
    PaymentSystemsModule,
    PromoCodeModule,
    SubscribeFormAdjustmentModule,
    TranslateModule,
  ],
  exports: [BuyModalComponent],
})
export class BuyModalModule {}
