import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { map, tap } from 'rxjs/operators';
import { SubscriptionContextService } from '../../context/subscription-context/subscription-context.service';
import { StorageContextService } from '../../context/storage-context/storage-context.service';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsResolver {
  constructor(
    private context: SubscriptionContextService,
    private storageContext: StorageContextService
  ) {}

  resolve(): Observable<boolean> | undefined {
    const accessToken = this.storageContext.getItem('access_token');

    if (!accessToken) {
      return;
    }

    if (this.context.get()) {
      return;
    }

    return this.context.retrieve().pipe(
      tap(({ items, upgrade_options }) => {
        this.context.next(items);

        if (upgrade_options?.length) {
          this.context.nextUpgrade(upgrade_options);
        }
      }),
      map(() => true)
    );
  }
}
