import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelpAccountComponent } from './help-account.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SignUpFromModule } from '../../../../../../auth/sign-up/sign-up-from/sign-up-from.module';
import { CustomDirectiveModule } from '../../../../../../shared/directives/custom-layout-directive/custom-flex-directive.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [HelpAccountComponent],
  exports: [HelpAccountComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    SignUpFromModule,
    CustomDirectiveModule,
    TranslateModule,
  ],
})
export class HelpAccountModule {}
