import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from '../../dialog/dialog.module';
import { DiscountExpirationModalComponent } from './discount-expiration-modal.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [DiscountExpirationModalComponent],
  imports: [CommonModule, DialogModule, TranslateModule],
  exports: [DiscountExpirationModalComponent],
})
export class DiscountExpirationModalModule {}
