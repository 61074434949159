<div class="selected-lesson">
  <h1 class="section-title text-center">
    {{ title | translate }}
  </h1>

  <div class="layout-row layout-justify-center layout-align-center">
    <div class="flex auto">
      <lesson-item
        [lesson]="lesson"
        [isBought]="selectedPackage.is_bought"
        [hideCalendarButton]="true" />
    </div>
  </div>
</div>
