import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PackageThumbnailComponent } from './package-thumbnail.component';
import { IconModule } from '../ui/icon/icon.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [PackageThumbnailComponent],
  imports: [CommonModule, IconModule, TranslateModule],
  exports: [PackageThumbnailComponent],
})
export class PackageThumbnailModule {}
