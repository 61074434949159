import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LessonRequiredParamsComponent } from './lesson-required-params.component';
import { DropdownModule } from '../../../../../../shared/components/ui/dropdown/dropdown.module';
import { DialogModule } from '../../../../../../shared/components/ui/dialog/dialog.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CustomDirectiveModule } from '../../../../../../shared/directives/custom-layout-directive/custom-flex-directive.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [LessonRequiredParamsComponent],
  imports: [
    CommonModule,
    DropdownModule,
    DialogModule,
    FlexLayoutModule,
    CustomDirectiveModule,
    TranslateModule,
  ],
  exports: [LessonRequiredParamsComponent],
})
export class LessonRequiredParamsModule {}
