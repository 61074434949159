import { APP_INITIALIZER, NgModule } from '@angular/core';
import { SendMessageService } from './services/send-message/send-message.service';
import { AppConfiguration, ENVIRONMENT_TOKEN } from './services/configuration/app.configuration';
import { environment } from '../../environments/environment';
import { NavigationService } from './services/navigation/navigation.service';
import { COMMON_BASE_URL } from './tokens/common-base-url.token';
import { FEEDBACK_URL } from './tokens/feedback-url.token';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AccessTokenInterceptorService } from './services/access-token-interceptor/access-token-interceptor.service';
import { GoogleAnalyticsService } from './services/google-analytics/google-analytics.service';
import { TogglePasswordService } from './services/toggle-password/toggle-password.service';
import { ErrorService } from './services/error/error.service';
import { RegexpService } from './services/regexp/regexp.service';
import { TimeService } from './services/time/time.service';
import { ViewportService } from './services/viewport/viewport.service';
import { VideoService } from './services/video/video.service';
import { SEOService } from './services/seo/seo.service';
import { UtmTagsService } from './services/utm-tags-service/utm-tags-service';
import { STRETCHITLIVE_PIXEl_URL } from './tokens/stretchitlive-pixel-url.token';
import { API_NEW_BASE_URL, PROGRAMS_NEW_BASE_URL } from './tokens/new-base-url.token';
import { SIZES_TOKEN } from './tokens/sizes.token';
import { AppSizes } from '../../config/sizes';
import { LocaleInterceptor } from './interceptors/locale.interceptor';

declare let ga: any;
declare let fbq: any;

function initializeNavigationFactory(navigation: NavigationService): () => void {
  return () => navigation.init();
}

function initializeFacebookPixelFactory(config: AppConfiguration): () => void {
  return () => {
    const chargebeeQuizzes = ['quiz93', 'quiz94'].find(quiz => window.location.href.includes(quiz));

    let id: string;

    if (chargebeeQuizzes) {
      id = config.pixels.fbPixelIDChargebee;
    } else {
      id = config.pixels.fbPixelIdStripe;
    }

    fbq('init', id);
  };
}

function initializeGoogleAnalyticsFactory(config: AppConfiguration): () => void {
  return () => {
    // ga('create', config.googleAnalytics, 'auto');
  };
}

@NgModule({
  providers: [
    VideoService,
    ViewportService,
    TimeService,
    ErrorService,
    RegexpService,
    TogglePasswordService,
    NavigationService,
    SendMessageService,
    GoogleAnalyticsService,
    SEOService,
    UtmTagsService,
    {
      provide: ENVIRONMENT_TOKEN,
      useFactory: () => environment,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeGoogleAnalyticsFactory,
      multi: true,
      deps: [AppConfiguration],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeFacebookPixelFactory,
      multi: true,
      deps: [AppConfiguration],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeNavigationFactory,
      deps: [NavigationService],
      multi: true,
    },
    {
      provide: COMMON_BASE_URL,
      useFactory: (config: AppConfiguration) => config.baseURL,
      deps: [AppConfiguration],
    },
    {
      provide: STRETCHITLIVE_PIXEl_URL,
      useFactory: (config: AppConfiguration) => config.stretchitivePixelURL,
      deps: [AppConfiguration],
    },
    {
      provide: SIZES_TOKEN,
      useValue: new AppSizes(),
    },
    {
      provide: PROGRAMS_NEW_BASE_URL,
      useFactory: (config: AppConfiguration) => config.programsNewUrl,
      deps: [AppConfiguration],
    },
    {
      provide: API_NEW_BASE_URL,
      useFactory: (config: AppConfiguration) => config.apiNewBaseUrl,
      deps: [AppConfiguration],
    },
    {
      provide: FEEDBACK_URL,
      useFactory: (config: AppConfiguration) => config.feedbackURL,
      deps: [AppConfiguration],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AccessTokenInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LocaleInterceptor,
      multi: true,
    },
  ],
})
export class CoreServicesModule {}
