import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateAccountModalComponent } from './create-account-modal.component';
import { DialogModule } from '../../dialog/dialog.module';
import { LoadingModule } from '../../loading/loading.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SignUpFromModule } from '../../../../../auth/sign-up/sign-up-from/sign-up-from.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [CreateAccountModalComponent],
  imports: [
    CommonModule,
    DialogModule,
    LoadingModule,
    FlexLayoutModule,
    SignUpFromModule,
    TranslateModule,
  ],
  exports: [CreateAccountModalComponent],
})
export class CreateAccountModalModule {}
