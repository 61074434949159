import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddDateFilterComponent } from './add-date-filter.component';
import { DateInputModule } from '../../../date-input/date-input.module';
import { DropdownModule } from '../../../dropdown/dropdown.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [AddDateFilterComponent],
  imports: [CommonModule, DateInputModule, DropdownModule, TranslateModule],
  exports: [AddDateFilterComponent],
})
export class AddDateFilterModule {}
