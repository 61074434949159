import { NgModule } from '@angular/core';
import { CertificateEntityModule } from './certificate-entity/certificate-entity.module';
import { CouponEntityModule } from './coupon-entity/coupon-entity.module';
import { EventEntityModule } from './event-entity/event-entity.module';
import { ChallengeEntityModule } from './challenge-entity/challenge-entity.module';
import { BannerEntityModule } from './banner-entity/banner-entity.module';
import { MetricEntityModule } from './metric-entity/metric-entity.module';

@NgModule({
  imports: [
    CertificateEntityModule,
    CouponEntityModule,
    EventEntityModule,
    ChallengeEntityModule,
    BannerEntityModule,
    MetricEntityModule,
  ],
})
export class EntitiesModule {}
