import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LessonFeedbackComponent } from './lesson-feedback.component';
import { FormsModule } from '@angular/forms';
import { DialogModule } from '../../dialog/dialog.module';
import { LoadingModule } from '../../loading/loading.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CustomDirectiveModule } from '../../../../directives/custom-layout-directive/custom-flex-directive.module';
import { InterpolateDirective } from '../../../../../core/directives/interpolation/interpolate.directive';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [LessonFeedbackComponent],
  imports: [
    CommonModule,
    FormsModule,
    DialogModule,
    LoadingModule,
    FlexLayoutModule,
    CustomDirectiveModule,
    InterpolateDirective,
    TranslateModule,
  ],
  exports: [LessonFeedbackComponent],
})
export class LessonFeedbackModule {}
