import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LessonVideoModalComponent } from './lesson-video-modal.component';
import { DialogModule } from '../../../../../shared/components/ui/dialog/dialog.module';
import { LoadingModule } from '../../../../../shared/components/ui/loading/loading.module';
import { LessonVideoModule } from './lesson-video/lesson-video.module';
import { LessonFeedbackModule } from './lesson-feedback/lesson-feedback.module';
import { LessonRecommendationModule } from './lesson-recommendation/lesson-recommendation.module';
import { LessonRequiredParamsModule } from './lesson-required-params/lesson-required-params.module';
import { LessonChallengePhotoModule } from './lesson-challenge-photo/lesson-challenge-photo.module';
import { LessonChallengeCompleteModule } from './lesson-challenge-complete/lesson-challenge-complete.module';
import { LessonChallengeDowngradeModule } from './lesson-challenge-downgrade/lesson-challenge-downgrade.module';
import { LessonDoneModule } from './lesson-done/lesson-done.module';
import { LessonChallengeLevelPremiumModule } from './lesson-challenge-level-premium/lesson-challenge-level-premium.module';

@NgModule({
  declarations: [LessonVideoModalComponent],
  imports: [
    CommonModule,
    DialogModule,
    LoadingModule,
    LessonVideoModule,
    LessonFeedbackModule,
    LessonRecommendationModule,
    LessonRequiredParamsModule,
    LessonChallengePhotoModule,
    LessonChallengeCompleteModule,
    LessonChallengeDowngradeModule,
    LessonChallengeLevelPremiumModule,
    LessonDoneModule,
  ],
  exports: [LessonVideoModalComponent],
})
export class LessonVideoModalModule {}
