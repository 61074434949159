import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChallengeJoinerJoinComponent } from './challenge-joiner-join.component';
import { DropdownModule } from '../ui/dropdown/dropdown.module';
import { PackageThumbnailModule } from '../package-thumbnail/package-thumbnail.module';
import { StFormFieldModule } from '../ui/st-form-field/st-form-field.module';
import { StDropdownModule } from '../../../core/components/ui/st-dropdown/st-dropdown.module';
import { FlexModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { ShowHideCheckboxModule } from '../ui/show-hide-checkbox/show-hide-checkbox.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ChallengeJoinerJoinComponent],
  imports: [
    CommonModule,
    DropdownModule,
    PackageThumbnailModule,
    StFormFieldModule,
    StDropdownModule,
    FlexModule,
    ReactiveFormsModule,
    ShowHideCheckboxModule,
    TranslateModule,
  ],
  exports: [ChallengeJoinerJoinComponent],
})
export class ChallengeJoinerJoinModule {}
