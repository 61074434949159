import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddClassToScheduleModalComponent } from './add-class-to-schedule-modal.component';
import { DialogModule } from '../../dialog/dialog.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DateInputModule } from '../../date-input/date-input.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CustomDirectiveModule } from '../../../../directives/custom-layout-directive/custom-flex-directive.module';
import { LoadingModule } from '../../loading/loading.module';
import { ShowHideCheckboxModule } from '../../show-hide-checkbox/show-hide-checkbox.module';
import { StFormFieldModule } from '../../st-form-field/st-form-field.module';
import { StDropdownModule } from '../../../../../core/components/ui/st-dropdown/st-dropdown.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [AddClassToScheduleModalComponent],
  imports: [
    CommonModule,
    DialogModule,
    FlexLayoutModule,
    DateInputModule,
    ReactiveFormsModule,
    CustomDirectiveModule,
    LoadingModule,
    ShowHideCheckboxModule,
    StFormFieldModule,
    StDropdownModule,
    TranslateModule,
  ],
  exports: [AddClassToScheduleModalComponent],
})
export class AddClassToScheduleModalModule {}
