import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LessonChallengeCompleteComponent } from './lesson-challenge-complete.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CustomDirectiveModule } from '../../../../../../shared/directives/custom-layout-directive/custom-flex-directive.module';
import { DialogModule } from '../../../../../../shared/components/ui/dialog/dialog.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [LessonChallengeCompleteComponent],
  imports: [CommonModule, FlexLayoutModule, CustomDirectiveModule, DialogModule, TranslateModule],
  exports: [LessonChallengeCompleteComponent],
})
export class LessonChallengeCompleteModule {}
