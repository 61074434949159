<div class="wrapper">
  <div class="content">
    <dashboard-header [componentType]="componentType" />

    <div class="auth-container">
      <router-outlet (activate)="routerActivate($event)" />
    </div>
  </div>

  <div class="other">
    <dashboard-footer />
  </div>
</div>
