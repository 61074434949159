import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { ApiModule } from './api/api.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './auth/auth.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ContextModule } from './context/context.module';
import { CookieNoticeModule } from './core/components/cookie-notice/cookie-notice.module';
import { CoreServicesModule } from './core/core-services.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { DialogModule } from './shared/components/ui/dialog/dialog.module';
import { DraftUserResolveService } from './resolvers/draft-user-resolver/draft-user-resolve.service';
import { EntitiesModule } from './entities/entities.module';
import { GiftCertificateModule } from './modules/gift-certificate/gift-certificate.module';
import { IndividualProgramService } from './shared/services/individual-program.service';
import { LicenseResolver } from './resolvers/license/license.resolver';
import { LoadingModule } from './shared/components/ui/loading/loading.module';
import { LocaleProvider } from './core/services/locale/locale.provider';
import { provideLottieOptions } from 'ngx-lottie';
import { MetricsResolver } from './pages/personalized-program/resolvers/metrics.resolver';
import { ModalCollectionModule } from './shared/components/ui/modal-collection/modal-collection.module';
import { NgModule } from '@angular/core';
import { PackagesResolver } from './resolvers/packages-resolver/packages-resolve.service';
import { PricesResolver } from './resolvers/prices-resolver/prices-resolve.service';
import { ProgramFiltersService } from './shared/services/program-filters.service';
import { ProgramGuard } from './guards/program-quard/program.guard';
import { QuizGuard } from './guards/quiz-guard/quiz.guard';
import { QuizImagesResolver } from './resolvers/quiz-images-resolver/quiz-images.resolver';
import { RedirectGuard } from './guards/redirect-guard/redirect.guard';
import { SpriteSvgModule } from './shared/components/sprite-svg/sprite-svg.module';
import { StVarModule } from './shared/directives/ngVar/st-var.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import localeData from 'dayjs/plugin/localeData';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localePt from '@angular/common/locales/pt';
import objectSupport from 'dayjs/plugin/objectSupport';
import { registerLocaleData } from '@angular/common';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { LoadingLogoComponent } from './shared/components/ui/loading-logo/loading-logo.component';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localeData);
dayjs.extend(objectSupport);
dayjs.extend(duration);

export function playerFactory() {
  return import('lottie-web');
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

registerLocaleData(localeDe, 'de');
registerLocaleData(localeEs, 'es');
registerLocaleData(localePt, 'pt');
registerLocaleData(localeEn, 'en');

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    CoreServicesModule,
    EntitiesModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    GiftCertificateModule,
    DialogModule,
    DashboardModule,
    ApiModule,
    BrowserModule,
    BrowserAnimationsModule,
    AuthModule,
    AppRoutingModule,
    CookieNoticeModule,
    LoadingModule,
    ContextModule,
    ModalCollectionModule,
    HammerModule,
    StVarModule,
    SpriteSvgModule,
    LoadingLogoComponent,
  ],
  providers: [
    LocaleProvider,
    QuizGuard,
    ProgramGuard,
    QuizImagesResolver,
    MetricsResolver,
    DraftUserResolveService,
    PricesResolver,
    PackagesResolver,
    ProgramFiltersService,
    IndividualProgramService,
    LicenseResolver,
    RedirectGuard,
    provideHttpClient(withInterceptorsFromDi()),
    provideLottieOptions({ player: playerFactory }),
  ],
})
export class AppModule {}
