import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaticPageModalModule } from '../modals/static-page-modal/static-page-modal.module';
import { CongratsModalModule } from '../modals/congrats-modal/congrats-modal.module';
import { CaloriesModalModule } from '../modals/calories-modal/calories-modal.module';
import { InfoModalModule } from '../modals/info-modal/info-modal.module';
import { CorrectEmailModalModule } from '../modals/correct-email-modal/correct-email-modal.module';
import { SaleModalModule } from '../modals/sale-modal/sale-modal.module';
import { ConfirmEmailSubscriptionModalModule } from '../modals/confirm-email-subscription-modal/confirm-email-subscription-modal.module';
import { AddAchievementModalModule } from '../modals/add-achievement-modal/add-achievement-modal.module';
import { SubscribeModalModule } from '../modals/subscribe-modal/subscribe-modal.module';
import { UpgradeWarningModalModule } from '../modals/upgrade-warning-modal/upgrade-warning-modal.module';
import { CropperModalModule } from '../modals/cropper-modal/cropper-modal.module';
import { BuyModalModule } from '../modals/buy-modal/buy-modal.module';
import { JoinChallengeModalModule } from '../modals/join-challenge-modal/join-challenge-modal.module';
import { AddToScheduleModalModule } from '../modals/add-to-schedule-modal/add-to-schedule-modal.module';
import { LessonVideoModalModule } from '../../../../dashboard/packages/components/lesson/lesson-video-modal/lesson-video-modal.module';
import { FirstTimeClassWarningModalModule } from '../modals/first-time-class-warning-modal/first-time-class-warning-modal.module';
import { EnterEmailModule } from '../modals/enter-email/enter-email.module';
import { GetProgramModalModule } from '../modals/get-program-modal/get-program-modal.module';
import { CreateAccountModalModule } from '../modals/create-account-modal/create-account-modal.module';
import { RestDayModalModule } from '../modals/rest-day-modal/rest-day-modal.module';
import { AddCartModalModule } from '../../../../dashboard/profile/profile-payment-methods/components/add-cart-modal/add-cart-modal.module';
import { AddGiftModalModule } from '../../../../dashboard/profile/profile-payment-methods/components/add-gift-modal/add-gift-modal.module';
import { HelpMeModalModule } from '../../../../dashboard/home/components/help-me-modal/help-me-modal.module';
import { AddClassToScheduleModalModule } from '../modals/add-class-to-schedule-modal/add-class-to-schedule-modal.module';
import { LessonFeedbackModule } from '../modals/lesson-feedback/lesson-feedback.module';
import { DidYouKnowModalModule } from '../modals/did-you-know-modal/did-you-know-modal.module';
import { ExtraSaleModalModule } from '../modals/extra-sale-modal/extra-sale-modal.module';
import { JoinChallengeSuccessfulModalModule } from '../modals/join-challenge-successful-modal/join-challenge-successful-modal.module';
import { UpgradeToPremiumModalModule } from '../modals/upgrade-to-premium-modal/upgrade-to-premium-modal.module';
import { DiscountExpirationModalModule } from '../modals/discount-expiration-modal/discount-expiration-modal.module';
import { UpSellModalModule } from '../modals/up-sell-modal/up-sell-modal.module';
import { UpSellWorkshopModalModule } from '../modals/up-sell-workshop-modal/up-sell-workshop-modal.module';
import { ChoosePlanModalModule } from '../modals/choose-plan-modal/choose-plan-modal.module';

@NgModule({
  declarations: [],
  imports: [
    AddClassToScheduleModalModule,
    CommonModule,
    StaticPageModalModule,
    CongratsModalModule,
    CaloriesModalModule,
    ChoosePlanModalModule,
    InfoModalModule,
    CorrectEmailModalModule,
    SaleModalModule,
    ConfirmEmailSubscriptionModalModule,
    AddAchievementModalModule,
    SubscribeModalModule,
    UpgradeWarningModalModule,
    CropperModalModule,
    BuyModalModule,
    JoinChallengeModalModule,
    AddToScheduleModalModule,
    FirstTimeClassWarningModalModule,
    LessonVideoModalModule,
    EnterEmailModule,
    GetProgramModalModule,
    CreateAccountModalModule,
    RestDayModalModule,
    AddCartModalModule,
    AddGiftModalModule,
    HelpMeModalModule,
    LessonFeedbackModule,
    DidYouKnowModalModule,
    ExtraSaleModalModule,
    JoinChallengeSuccessfulModalModule,
    UpgradeToPremiumModalModule,
    DiscountExpirationModalModule,
    UpSellModalModule,
    UpSellWorkshopModalModule,
  ],
})
export class ModalCollectionModule {}
