import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JoinChallengeSuccessfulModalComponent } from './join-challenge-successful-modal.component';
import { DialogModule } from '../../dialog/dialog.module';
import { LoadingModule } from '../../loading/loading.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CustomDirectiveModule } from '../../../../directives/custom-layout-directive/custom-flex-directive.module';
import { ChallengeJoinerUpdateModule } from '../../../challenge-joiner-update/challenge-joiner-update.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [JoinChallengeSuccessfulModalComponent],
  imports: [
    CommonModule,
    DialogModule,
    LoadingModule,
    FlexLayoutModule,
    CustomDirectiveModule,
    ChallengeJoinerUpdateModule,
    TranslateModule,
  ],
  exports: [JoinChallengeSuccessfulModalComponent],
})
export class JoinChallengeSuccessfulModalModule {}
