import { NgModule } from '@angular/core';
import { CookieNoticeComponent } from './cookie-notice.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [CookieNoticeComponent],
  exports: [CookieNoticeComponent],
  imports: [TranslateModule],
})
export class CookieNoticeModule {}
