import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LessonChallengeLevelPremiumComponent } from './lesson-challenge-level-premium.component';
import { DialogModule } from '../../../../../../shared/components/ui/dialog/dialog.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LoadingModule } from '../../../../../../shared/components/ui/loading/loading.module';
import { TranslateModule } from '@ngx-translate/core';
import { InterpolateDirective } from '../../../../../../core/directives/interpolation/interpolate.directive';

@NgModule({
  declarations: [LessonChallengeLevelPremiumComponent],
  imports: [
    CommonModule,
    DialogModule,
    FlexLayoutModule,
    LoadingModule,
    TranslateModule,
    InterpolateDirective,
  ],
  exports: [LessonChallengeLevelPremiumComponent],
})
export class LessonChallengeLevelPremiumModule {}
